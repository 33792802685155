import React, { useEffect, useState, useRef, useMemo } from "react";
import { REACT_APP_BACKEND_URL } from "../config/config";
import Table from "./ColorDataTable";
import { Modal } from "react-bootstrap";
import { Button } from "@mui/material";
import { useReactToPrint } from "react-to-print";
  import { ToastContainer, toast } from "react-toastify";


const ShowColor = (props) => {
  const colorss = props.colorss;
  const [colors, setColors] = useState([colorss]);
  const UserData = localStorage.getItem("token");

  const getColors = async () => {
    const response = await fetch(`${REACT_APP_BACKEND_URL}/user/color/getAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: UserData,
      },
    });
    const json = await response.json();

    if (json.status) {
      setColors(json.Data);
    } else {
      alert("Invalid Details");
    }
  };

  useEffect(() => {
    getColors();
  }, [colorss]);

  const [show, setShow] = useState(false);

  const ComponentPdf = useRef();
  


    const handlePrint = useReactToPrint({
      content: () => ComponentPdf.current,
      documentTitle: "Color Price List",
      onAfterPrint: () => toast.success("Data saved"),
    });


    const handleEdit = async (color) => {
      console.log("%c Line:35 🍖 color", "color:#4fff4B", color);

      let name = prompt(
        "Please enter your updated color name:",
        color.color_name
      );
      console.log("%c Line:38 🎂 name", "color:#3f7cff", name);

      let price = prompt("Please enter your updated price:", color.price);

      let datas = {
        name: name ? name : color.color_name,
        price: price ? price : color.price,
      };

      if (name || price) {
        const response = await fetch(
          `${REACT_APP_BACKEND_URL}/user/color/update/${color._id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: UserData,
            },
            body: JSON.stringify(datas),
          }
        );

        const json = await response.json();
        console.log("%c Line:61 🍓 json", "color:#ed9ec7", json);

        if (json.status) {
          getColors();

          toast.success("color updated successfully");
        } else {
          toast.error('Error in updating');
        }
      }
    };

    const clickhandler = async (id) => {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/user/color/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: UserData,
          },
        }
      );

      const json = await response.json();

      if (json.status) {
        getColors();
        toast.success("color deleted successfully");
      } else {
        toast.error(json.message);
      }
    };

    return (
      <div>
        <Table
          data={colors}
          Deleteclick={clickhandler}
          Editclick={handleEdit}
        />

        <button className="btn btn-success" onClick={handlePrint}>
          Export Price List PDF
        </button>
        <div style={{ display: "none" }}>
          <div ref={ComponentPdf}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Sr No.</th>
                  <th scope="col">Color Name</th>
                  <th scope="col">Price</th>
                </tr>
              </thead>
              <tbody>
                {colors.map((color, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{color.color_name}</td>
                    <td>{color.price}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

 
      </div>
    );
  
};

export default ShowColor;
