import React, { useMemo } from "react";

import DataTable from "react-data-table-component";
import FilterComponent from "./FilterComponent";

const Table = (props) => {
  const columns = [
    {
      name: "Company Name",
      selector: "Processor_name",
      sortable: true,
      grow: 2,
    },
    {
      name: "Customer Name",
      selector: "Processor_men",
      sortable: true,
      grow: 2,
    },

    {
      name: "Buttons",
      button: true,
      cell: (row) => (
        <>
          <i
            className="far fa-edit mx-2 cursor-pointer text-blue-600"
            onClick={() => props.Editclick(row)}
            style={{ marginRight: "5px" }}
          ></i>
          <i
            className="far fa-trash-alt mx-2 cursor-pointer text-red-600"
            onClick={() => props.Deleteclick(row._id)}
          ></i>
        </>
      ),
    },
  ];

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  // const filteredItems = data.filter(
  //   item => item.name && item.name.includes(filterText)
  // );
  const filteredItems = props.data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      title="Customer List"
      columns={columns}
      data={filteredItems}
      defaultSortField="name"
      striped
      pagination
      subHeader
      subHeaderComponent={subHeaderComponent}
    />
  );
};

export default Table;
