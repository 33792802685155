import logo from "./logo.svg";
import "./App.css";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Switch,
  Routes,
  Link,
  Router,
} from "react-router-dom";
import { BrowserRouter } from "react-router-dom";

import Navbar from "./components/Navbar";

import Login from "./components/Login";
import Signup from "./components/SignUp";
import React, { useEffect, useState } from "react";

import Home from "./components/Home";
import Color from "./components/Color";
import Bill from "./components/Bill";
import Customer from "./components/Customer";
import Rojmel from "./components/Rojmel";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Flip } from "react-toastify";

function App() {
  const isAuthenticated = localStorage.getItem("token") ? true : false;

  // useEffect(() => {
  //   toast.configure({
  //     position: "top-center",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     draggable: true,
  //   });
  // }, []);

  return (
    <div className="App">
      <ToastContainer
        position={toast.POSITION.TOP_RIGHT}
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Flip}
      ></ToastContainer>

      <Navbar />
      <Routes>
        {isAuthenticated && <Route exact path="/" element={<Home />}></Route>}
        {isAuthenticated && (
          <Route exact path="/color" element={<Color />}></Route>
        )}
        {isAuthenticated && (
          <Route exact path="/bill" element={<Bill />}></Route>
        )}
        {isAuthenticated && (
          <Route exact path="/customer" element={<Customer />}></Route>
        )}
        {isAuthenticated && (
          <Route exact path="/rojmel" element={<Rojmel />}></Route>
        )}

        <Route exact path="/login" element={<Login />}></Route>
        <Route exact path="/signup" element={<Signup />}></Route>
      </Routes>
    </div>
  );
}

export default App;
