import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ShowColor from "./ShowColor";
import { REACT_APP_BACKEND_URL } from "../config/config";
import ShowCustomer from "./ShowCustomer";
import { ToastContainer, toast } from "react-toastify";

const Customer = () => {
  const navigate = useNavigate();
  const UserData = localStorage.getItem("token");
  const [customerss, setCustomerss] = useState([]);
  const [customers, setCustomers] = useState({
    processorname: "",
    processormen: "",
  });
  const onChange = (e) => {
    setCustomers({ ...customers, [e.target.name]: e.target.value });
  };

  const getColors = async () => {
    const response = await fetch(
      `${REACT_APP_BACKEND_URL}/user/customer/getAll`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: UserData,
        },
      }
    );
    const json = await response.json();
    console.log("getcolors", json);
    if (json.status) {
      setCustomerss(json.Data.docs);
    } else {
      alert("Invalid Details");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { processorname, processormen } = customers;

    if (processorname === "" && processormen === "") {
      alert("please fill the form");
      return false;
    }

    const response = await fetch(`${REACT_APP_BACKEND_URL}/user/customer/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: UserData,
      },
      body: JSON.stringify({ processorname, processormen }),
    });

    const json = await response.json();

    if (json.status) {
      // Save the auth token and redirect
      setCustomers({ ...customers, processormen: "", processorname: "" });


      toast.success("customer added successful");

      getColors();

      // setIsLoading(true);
    } else {
    //   alert("Invalid Details");
        toast.error(json.message);
    



    }
  };
  console.log("%c Line:115 🥤 customerss", "color:#2eafb0", customerss);
  return (
    <div className="mt-8">
      <div className="container mx-auto">
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label
              htmlFor="processorname"
              className="block text-sm font-medium text-gray-700"
            >
              processor Name
            </label>
            <input
              type="text"
              className="mt-1 p-2 border rounded-md w-full"
              id="processorname"
              name="processorname"
              value={customers.processorname}
              onChange={onChange}
            />
            <label
              htmlFor="processormen"
              className="block text-sm font-medium text-gray-700"
            >
              processor men
            </label>
            <input
              type="text"
              className="mt-1 p-2 border rounded-md w-full"
              id="processormen"
              name="processormen"
              value={customers.processormen}
              onChange={onChange}
            />

            <button
              type="submit"
              className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      <ShowCustomer customerss={customerss} />
    </div>
  );
};

export default Customer;
