import "./Bill.css";
import React, { useState, useEffect } from "react";
import InvoiceForm from "./InvoiceForm";
import { REACT_APP_BACKEND_URL } from "../config/config";
  import { ToastContainer, toast } from "react-toastify";


const Bill = () => {
  const [colors, setColors] = useState([]);
  const UserData = localStorage.getItem("token");

  useEffect(() => {
    const getColors = async () => {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/user/color/getAll`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: UserData,
          },
        }
      );

      const json = await response.json();
      if (json.status) {
        setColors(json.Data);
      } else {
        // alert("Invalid Details");
        toast.error("Invalid Details");
      }
    };

    getColors();
  }, []);

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="mx-auto max-w-7xl">
        <InvoiceForm colors={colors} />
      </div>
    </div>
  );
};

export default Bill;
