import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ShowColor from "./ShowColor";
import { REACT_APP_BACKEND_URL } from "../config/config";
import Alert from "./Alert";
  import { ToastContainer, toast } from "react-toastify";


const Color = () => {
  const navigate = useNavigate();
  const [colorss, setColorss] = useState([]);
  const UserData = localStorage.getItem("token");
  const [colors, setColors] = useState({
    name: "",
    price: "",
  });


  const onChange = (e) => {
    setColors({ ...colors, [e.target.name]: e.target.value });
  };

  const getColors = async () => {
    const response = await fetch(`${REACT_APP_BACKEND_URL}/user/color/getAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: UserData,
      },
    });
    const json = await response.json();


    if (json.status) {
      setColorss(json.Data.docs);
    } else {
      // alert("Invalid Details");
      toast.error("Invalid Details");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, price } = colors;

    if (name === "" || price === "") {
      alert("please fill the form");
      return false;
      
    }

    const response = await fetch(`${REACT_APP_BACKEND_URL}/user/color/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: UserData,
      },
      body: JSON.stringify({ name, price }),
    });

    const json = await response.json();

    if (json.status) {
      // Save the auth token and redirect
      setColors({ ...colors, name: "", price: "" });

      toast.success("color added successful");

      getColors();

      // setIsLoading(true);
    } else {

      toast.error("Invalid Details");
    }
  };

  return (
    <div className="mt-8">
      <div className="container mx-auto">
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label
              htmlFor="colour_name"
              className="block text-sm font-medium text-gray-700"
            >
              Color Name
            </label>
            <input
              type="text"
              className="mt-1 p-2 border rounded-md w-full"
              id="name"
              name="name"
              required="true"
              value={colors.name}
              onChange={onChange}
            />
            <label
              htmlFor="price"
              className="block text-sm font-medium text-gray-700"
            >
              Price
            </label>
            <input
              type="text"
              required="true"
              className="mt-1 p-2 border rounded-md w-full"
              id="price"
              name="price"
              value={colors.price}
              onChange={onChange}
            />

            <button
              type="submit"
              className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      <ShowColor colorss={colorss} />
    </div>
  );
};

export default Color;
