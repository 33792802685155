import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { REACT_APP_BACKEND_URL } from "../config/config";
import Alert from "./Alert";
  import { ToastContainer, toast } from "react-toastify";
  // import "react-toastify/dist/ReactToastify.css";


const Login = () => {
  const [credentials, setCredentials] = useState({ email: "", password: "" });

  const navigate = useNavigate();

  const onchange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleclick = async (e) => {
    console.log(REACT_APP_BACKEND_URL);
    e.preventDefault();
    const response = await fetch(`${REACT_APP_BACKEND_URL}/user/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        email: credentials.email,
        password: credentials.password,
      }),
    });

    const json = await response.json();


    if (json.status &&  json) {
      //save the auth token and redirect
      localStorage.setItem("token", json.data);
      localStorage.setItem("processer_name", json.name);
      localStorage.setItem("processer_email", json.email);
      localStorage.setItem("processer_address", json.address);
      localStorage.setItem("processer_phone", json.phone);
      localStorage.setItem("processer_id", json.processorId);


      // alert(json.message);

      toast.success(json.message, { appearance: "success" }); 



      navigate("/");
    } else {
      toast.error(json.message, { appearance: "error" });
    }
  };

  return (
    <div>
      <form onSubmit={handleclick}>
        <div className="mb-3">
          <label
            htmlFor="exampleInputEmail1"
            className="block text-sm font-medium text-gray-700"
          >
            Email address
          </label>
          <input
            type="email"
            name="email"
            onChange={onchange}
            required='true'
            value={credentials.email}
            className="mt-1 block w-full rounded-md shadow-sm border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
          />
          <p className="mt-2 text-sm text-gray-500" id="email">
            We'll never share your email with anyone else.
          </p>
        </div>
        <div className="mb-3">
          <label
            htmlFor="exampleInputPassword1"
            className="block text-sm font-medium text-gray-700"
          >
            Password
          </label>
          <input
            type="password"
            onChange={onchange}
            value={credentials.password}
            required='true'
            name="password"
            className="mt-1 block w-full rounded-md shadow-sm border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            id="password"
          />
        </div>

        <button
          type="submit"
          className="w-full inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default Login;
