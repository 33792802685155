import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { REACT_APP_BACKEND_URL } from "../config/config";
  import { ToastContainer, toast } from "react-toastify";


const Navbar = () => {
  let location = useLocation();
  let navigate = useNavigate();

  let processar = localStorage.getItem("processer_name");
  console.log(processar);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("processer_name");

    toast.success("Logout successful", { appearance: "success" });
    navigate("/login");
  };

  return (
    <div>
      <nav className=" bg-gray-900">
        <div className=" mx-auto flex items-center justify-between py-4">
          <Link className="text-white text-xl font-semibold" to="/">
            {processar}
          </Link>
          <button
            className="text-white navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className=" md:flex md:ml-auto space-x-4">
            {localStorage.getItem("token") && (
              <Link
                className={`text-white ${
                  location.pathname === "/" ? "underline" : ""
                }`}
                aria-current="page"
                to="/color"
              >
                Color
              </Link>
            )}
            {localStorage.getItem("token") && (
              <Link
                className={`text-white  ${
                  location.pathname === "/" ? "underline" : ""
                }`}
                aria-current="page"
                to="/bill"
              >
                Bill
              </Link>
            )}
            {localStorage.getItem("token") && (
              <Link
                className={`text-white  ${
                  location.pathname === "/" ? "underline" : ""
                }`}
                aria-current="page"
                to="/customer"
              >
                Customer
              </Link>
            )}
            {localStorage.getItem("token") && (
              <Link
                className={`text-white  ${
                  location.pathname === "/" ? "underline" : ""
                }`}
                aria-current="page"
                to="/rojmel"
              >
                Rojmel
              </Link>
            )}

            {!localStorage.getItem("token") ? (
              <form className="flex">
                <Link
                  className="bg-blue-500 text-white py-2 px-4 rounded"
                  to="/login"
                  role="button"
                >
                  Login
                </Link>
                <Link
                  className="bg-blue-500 text-white py-2 px-4 rounded"
                  to="/signup"
                  role="button"
                >
                  Signup
                </Link>
              </form>
            ) : (
              <button
                onClick={handleLogout}
                className="bg-blue-500 text-white py-2 px-4 rounded"
              >
                LogOut
              </button>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
