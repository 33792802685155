import React, { useEffect, useState, useRef, useMemo } from "react";
import { REACT_APP_BACKEND_URL } from "../config/config";
import Table from "./CustomerDataTable";
  import { ToastContainer, toast } from "react-toastify";



const ShowCustomer = (props) => {
  const customerss = props.customerss;
  const [customer, setCustomer] = useState([customerss]);
  const UserData = localStorage.getItem("token");

  const getColors = async () => {
    const response = await fetch(
      `${REACT_APP_BACKEND_URL}/user/customer/getAll`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: UserData,
        },
      }
    );
    const json = await response.json();


    if (json.status) {
      setCustomer(json.Data);
    } else {
      alert("Invalid Details");
    }
  };

  useEffect(() => {
    getColors();
  }, [customerss]);

  const deleteColor = async (id) => {
    const response = await fetch(
      `${REACT_APP_BACKEND_URL}/user/customer/delete/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: UserData,
        },
      }
    );

    const json = await response.json();


    if (json.status) {
      getColors();
      toast.success("Customer deleted successfully");
    } else {
      // alert("Invalid Details")
      
      toast.error(json.message);
    }
  };

  const updatedColor = async (color) => {
   


      console.log("%c Line:35 🍖 color", "color:#4fff4B", color);

      let processorname = prompt(
        "Please enter your updated company name:",
        color.Processor_name
      );
      console.log("%c Line:38 🎂 name", "color:#3f7cff", processorname);

      let processormen = prompt(
        "Please enter your updated customer name:",
        color.Processor_men
      );

      let datas = {
        processorname: processorname ? processorname : color.Processor_name,
        processormen: processormen ? processormen : color.Processor_men,
      };

      if (processormen || processorname) {
        const response = await fetch(
          `${REACT_APP_BACKEND_URL}/user/customer/update/${color._id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: UserData,
            },
            body: JSON.stringify(datas),
          }
        );

        const json = await response.json();
        console.log("%c Line:61 🍓 json", "color:#ed9ec7", json);

        if (json.status) {
          getColors();

          toast.success("Customer updated successfully");
        } else {
          toast.error('Error in updating');
        }
      }
  };

  console.log("%c Line:111 🍿 customerss", "color:#b03734", customer);
  return (
    <div>
      <Table
        data={customer}
        Deleteclick={deleteColor}
        Editclick={updatedColor}
      />
    </div>
  );
};

export default ShowCustomer;
