import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { REACT_APP_BACKEND_URL } from "../config/config";
import "./Rojmel.css";
import "./table.css";
import moment from "moment";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const Rojmel = () => {
  const UserData = localStorage.getItem("token");
  console.log(
    "%c Line:12 🍧 moment().format('yyyy-dd-mm')",
    "color:#42b983",
    moment().format("yyyy-DD-MM")
  );
  const [dates, setDates] = useState({
    StartDate: moment().format("yyyy-DD-MM"),
    EndDate: "",
    company_name: "",
  });
  let total = 0;

  const [AllData, setAllData] = useState([]);

  const [allCompanyName, setAllCompanyName] = useState([]);

  const getAllCompanyName = async () => {
    const response = await fetch(
      `${REACT_APP_BACKEND_URL}/user/customer/getAll`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: UserData,
        },
      }
    );
    const json = await response.json();

    let customernames = json.Data.map((item) => {
      return item.Processor_name;
    });

    console.log("%c Line:34 🍅 json", "color:#e41a6a", customernames);
    if (json.status) {
      setAllCompanyName(customernames);
    } else {
      alert("Invalid Details");
    }
  };
  useEffect(() => {
    getAllCompanyName();
  }, []);

  const onChange = (e) => {
    setDates({ ...dates, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { StartDate, EndDate, company_name } = dates;

    setAllData([]);
    const response = await fetch(`${REACT_APP_BACKEND_URL}/user/bill/getBill`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: UserData,
      },
      body: JSON.stringify({ StartDate, EndDate, company_name }),
    });
    const json = await response.json();
    console.log("%c Line:34 🍅 json", "color:#e41a6a", json);
    setAllData(json.Data.docs);

    if (json.status) {
      // Save the auth token and redirect
      setDates({
        ...dates,
        StartDate: "",
        EndDate: "",
        company_name: "",
      });
    } else {
      alert("Invalid Details");
    }
  };
  return (
    <div>
      <form class="form" onSubmit={handleSubmit}>
        <div class="flex">
          <label>
            Start Date
            <input
              required="true"
              placeholder="Start Date"
              class="input"
              type="date"
              id="StartDate"
              name="StartDate"
              onChange={onChange}
              value={dates.StartDate}
            />
          </label>

          <label>
            End Date
            <input
              required="true"
              placeholder="End Date"
              class="input"
              type="date"
              format="yyyy-mm-dd"
              id="EndDate"
              name="EndDate"
              onChange={onChange}
              value={dates.EndDate}
            />
          </label>
        </div>

        {/* <label>
          <input
            required="true"
            placeholder="Company Name"
            type="text"
            class="input"
            name="company_name"
            onChange={onChange}
            value={dates.company_name}
          />
        {/* </label> } */}

        <span>Company Name</span>

        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={allCompanyName}
          sx={{ width: 300 }}
          onChange={(event, newValue) => {
            setDates({ ...dates, company_name: newValue });
          }}
          renderInput={(params) => <TextField {...params} />}
        />

        <button class="fancy" href="#">
          <span class="top-key"></span>
          <span class="text">submit</span>

          <span class="bottom-key-1"></span>
          <span class="bottom-key-2"></span>
        </button>
      </form>
      {/* <h1>{dates.company_name} creation 1/8/23 to 31/8/23 colour rojmel</h1> */}

      {AllData?.length > 0 && (
        <table className="App rojmel-table">
          <thead>
            <tr>
              <th scope="col" className="px-4 py-2">
                Date
              </th>
              <th scope="col" className="px-4 py-2">
                Invoice No
              </th>
              <th scope="col" className="px-4 py-2">
                Colour Name
              </th>
              <th scope="col" className="px-4 py-2">
                Colour Price
              </th>
              <th scope="col" className="px-4 py-2">
                Colour Weight
              </th>
              <th scope="col" className="px-4 py-2">
                Sub Total
              </th>
              <th scope="col" className="px-4 py-2">
                Company Name
              </th>
              <th scope="col" className="px-4 py-2">
                Customer Name
              </th>
            </tr>
          </thead>
          <tbody>
            {AllData.map((color) =>
              color.colours.map((colorss) => {
                total += colorss.subTotal;
                return (
                  <tr key={color._id}>
                    <td className="px-4 py-2">
                      {moment(color.createdAt).format("DD/MM/YYYY")}
                    </td>
                    <td className="px-4 py-2">{color.invoice_number}</td>
                    <td className="px-4 py-2">{colorss.color_name}</td>
                    <td className="px-4 py-2">{colorss.price}</td>
                    <td className="px-4 py-2">{colorss.weight}</td>
                    <td className="px-4 py-2">{colorss.subTotal}</td>
                    <td className="px-4 py-2">{color.company_name}</td>
                    <td className="px-4 py-2">{color.customer_name}</td>
                  </tr>
                );
              })
            )}
            <tr>
              <td className=""></td>
              <td className="px-4 py-2"></td>
              <td className="px-4 py-2"></td>
              <td className="px-4 py-2"></td>
              <td className="px-4 py-2"></td>
              <td className="px-4 py-2">total: {total}</td>
              <td className="px-4 py-2"></td>
              <td className="px-4 py-2"></td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Rojmel;
