import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { REACT_APP_BACKEND_URL } from "../config/config";
  import { ToastContainer, toast } from "react-toastify";




const SignUp = () => {
  let navigate = useNavigate();

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    processor_name: "",
    phone:"",
    address:""

  });

  const onchangehandle = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password, processor_name ,phone,address} = credentials;
    const response = await fetch(`${REACT_APP_BACKEND_URL}/user/auth/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password, processor_name,phone,address }),
    });
    const json = await response.json();
    console.log("json", json);

    if (json.status) {

      toast.success(json.message, { appearance: "success" });
      navigate("/login");
    } else {
      toast.error(json.message, { appearance: "error" });
    }
  };

  return (
    <div>
      <div className="container mx-auto">
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label
              htmlFor="processor_name"
              className="block text-sm font-medium text-gray-700"
            >
              Processor Name
            </label>
            <input
              type="text"
              className="mt-1 p-2 border rounded-md w-full"
              id="processor_name"
              name="processor_name"
              onChange={onchangehandle}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email address
            </label>
            <input
              type="email"
              className="mt-1 p-2 border rounded-md w-full"
              id="email"
              name="email"
              onChange={onchangehandle}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="number"
              className="block text-sm font-medium text-gray-700"
            >
              Phone Number
            </label>
            <input
              type="number"
              className="mt-1 p-2 border rounded-md w-full"
              id="phone"
              name="phone"
              onChange={onchangehandle}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="address"
              className="block text-sm font-medium text-gray-700"
            >
              Address
            </label>
            <input
              type="address"
              className="mt-1 p-2 border rounded-md w-full"
              id="address"
              name="address"
              onChange={onchangehandle}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              type="password"
              className="mt-1 p-2 border rounded-md w-full"
              id="password"
              name="password"
              onChange={onchangehandle}
            />
          </div>

          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
