import React from "react";



const Home = () => {
  return (
    <div className="font-bold text-4xl">
      <h1>Welcome page </h1>

    </div>
  );
};

export default Home;
